<template>
  <div class="join-details">
    <w-title  model="职位" modelkey="详情"  :isDetails="false"></w-title>
    <div class="details-info">
      <h2>{{ currentDetails.type }}</h2>
      <div class="base">
              <span>{{ currentDetails.provinceId }}/{{ currentDetails.cityId }}</span>
              <span class="split">|</span>
              <span>{{ currentDetails.name }}</span>
              <span class="split">|</span>
              <span>{{ getExperience(currentDetails.experience) }}</span>
              <br class="block">
              <span class="time">时间：{{ currentDetails.createTime }}</span>
       </div>
       <h3>职位描述</h3>
       <div class="description-info"><span class="none">职位描述：<br></span>
       <span style="white-space: pre-line;" v-html="currentDetails.description"></span>
       </div>
       <h3>职位要求</h3>
       <div class="description-info"><span class="none">职位要求：<br></span>
       <span style="white-space: pre-line;" v-html="currentDetails.requirement"></span>
       </div>
       <h3>投递方式</h3>
       <div class="description-info"><span class="none">投递方式：<br></span>
       <span >请将简历投递至公司人力资源部邮箱: stkjadmin@stkjiot.com 邮件主题请注明应聘岗位名称及姓名。</span>
       </div>
    </div>
    <div class="back" @click="goBack">
      <span class="iconfont iconxia " ></span>
      <span>返回列表</span>
    </div>
  </div>
</template>

<script>
import wTitle from "@/components/sTitle/viewtitle";
import {getRecruitListApi} from '@/api/index.js'

export default {
  name: 'joinDetails',
  components: {wTitle},
  data() {
    return {
      currentDetails: {}
    }
  },
  created() {},


  mounted() {
    this.getCurrentInfo()
  },
  methods: {
    getExperience(val){
      switch (true) {
        case val == 1:
         return '不限';
        case val == 2:
          return '1-3年';
        case val == 3:
          return '3-5年';
        case val == 4:
          return '5年以上';
        default:'不限'
          break;
      }

    },
    goBack(){
      this.$router.go(-1)
    },
    getCurrentInfo(){
      getRecruitListApi({id:this.$route.query.item.id}).then(res=>{
        this.currentDetails = res.data.data.list[0]
    } )
    }
  }
}
</script>

<style lang="scss" scoped>
  .join-details{
    padding:  160px 18.75vw 80px 18.75vw;
    background-color: #161616;
    .details-info {
      background-color: #1d1d1d;
      padding: 2.0833vw;
      color: #fff;
      h2 {
        font-size: 2.2917vw;
        font-weight: normal;
      }
      .base {
        font-size: .8333vw;
        margin-top: 1.25vw;
        margin-bottom: 2.5vw;
        .block {
          display: none;
        }
        .split {
          margin: 0 .5208vw;
        }
        .time {
          margin-left: 2.0833vw;
          color: #999999;
        }
      }
      .description-info {
        color: #B3B3B3;
        font-size: .8333vw;
        line-height: 1.6;
      }
    }
    h3 {
      margin-top: 1.6667vw;
      font-size: 1.4583vw;
      font-weight: normal;
      margin-bottom: 1.25vw;
    }
    .back {
  margin-top: 4.1667vw;
  width: 100%;
  height: 2.8125vw;
  color: #fff;
  background-color: #1d1d1d;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #172820;
    color: #1DBF73;
    cursor: pointer;
  }
  span:nth-child(1) {
    display: block;
    transform: rotate(90deg) !important;
    margin-right: .4167vw;
    font-size: 14px;
  }
}
  }
  @media only screen and (max-width: $mobile-width){
    .join-details{
    padding:  1.4679rem 10.75vw .7339rem 10.75vw;
    background-color: #161616;
    .details-info {
      background-color: #1d1d1d;
      padding: 2.0833vw;
      color: #fff;
      h2 {
        font-size: 2.2917vw;
        font-weight: normal;
      }
      .base {
        font-size: .8333vw;
        margin-top: 1.25vw;
        margin-bottom: 2.5vw;
        .block {
          display: block;
        }
        .split {
          margin: 0 .5208vw;
        }
        .time {
          margin-left: 0;
          margin-top: 5px;
          color: #999999;
        }
      }
      .description-info {
        color: #B3B3B3;
        font-size: .8333vw;
        line-height: 1.6;
        .none {
          display: none;
        }
      }
    }
    h3 {
      margin-top: 1.6667vw;
      font-size: 1.4583vw;
      font-weight: normal;
      margin-bottom: 1.25vw;
    }
    .back {
      margin-top: 4.1667vw;
      width: 100%;
      height: .4954rem;
      color: #fff;
      background-color: #1d1d1d;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: #172820;
        color: #1DBF73;
        cursor: pointer;
      }
      span:nth-child(1) {
        display: block;
        transform: rotate(90deg) !important;
        margin-right: .4167vw;
        font-size: .2167rem;
      }
      span:nth-child(6) {
        display: block;
        transform: rotate(90deg) !important;
        margin-right: 0;
        font-size: .2167rem;
      }
    }
      }
  }
</style>
